@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700');

html {
  font-family: 'Roboto', 'Helvetica Neue', Arial, Helvetica, sans-serif;
}

html,
body,
#root,
#root > div {
  background-color: transparent;
  margin: 0;
  padding: 0;
  height: 100%;
}

button,
input,
select,
label,
body {
  color: rgba(0, 0, 0, 0.85);
  font-size: 16px;
}

iframe {
  margin: 0;
  padding: 0;
  border: none;
  width: 100%;
  height: 100%;
}

button {
  padding: 0.5em 1em;
  width: 300px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.button.primary {
  background-color: #8ec165;
  border: 1px solid #85b35f;
  color:  #ffffff;
}

.button.primary.disabled {
  opacity: 0.5;
}

.button.default {
  background-color: #ffffff;
  border: 1px solid #dedede;
}

.grid { 
  display: flex;
  justify-content: space-between;
  align-items: stretch;
}

.grid.column {
  flex-direction: column;
}

.grid.row {
  flex-direction: row;
}

.grid.column.guttered *:not(:first-child) {
  margin-left: 0.5em;;
}

.grid.row.guttered *{
  margin-bottom: 0.5em;
}

.apm-message {
  text-align: center;
}

input {
  font-weight: 400;
  font-size: 13.5px;  
}

.card-data {
  display: flex;
  flex-direction: row;
  min-width: 486px;
  min-height: 315px;
}

.card-data-column-left {
  padding: 0 40px 0 0;
  min-width: 280px;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.card-data-column-right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 240px;
}

.no-line-break {
  white-space: nowrap;
}

.card-data-field-margin {
  margin-right: 15px;
}

.card-data-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.card-order-container {
  display: flex;
  flex-direction: column;
  white-space: nowrap;
}

.card-order-divider {
  border-bottom: solid 1px #EAEAEA;
}

.bold {
  font-weight: bold !important;
}

.card-order-line {
  display: flex;
  justify-content: space-between;
  margin-top: 7px;
  margin-bottom: 7px;
}

.card-order-line span {
  white-space: nowrap;
  font-size: 12px;
}

.card-order-price {
  font-weight: 500;
}

.card-expire-section {
  display: flex;
  flex-direction: row;
}

.card-data-max-w1 {
  max-width: 60px;
}

.card-data-max-w2 {
  max-width: 60px;
}

.card-data-max-w3 {
  max-width: 75px;
}

.loading-dots--dot {
  background-color: rgba( 100, 100, 100, 0.5 ) !important;
}

input:focus { 
  outline: none;
  border: solid 1px rgb(109,189,191);
}

.card-data-input-field {
  border: solid 1px #DEDEDE;
  height: 36px;
  font-size: 14px;
  border-radius: 4px;
  padding-left: 13px;
  margin-top: 8px;
}

.card-data-input-field.invalid {
  border-color: #ff8282;;
}

.card-form-caption {
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
}

.card-mmYYcvv {
  min-width: 20px;
  max-width: 85px;
  overflow: hidden;
}

.card-field-label {
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  display: flex;
  flex-direction: column;
  margin-bottom: 1.4rem;
}

.card-data-error-message-container {
  color: #D8000C;
  background-color: #ffebeb;
  display: flex;
  padding: 12px;
  border-radius: 5px;
}

.card-column-header {
  font-size: 20px;
  font-style: normal;
  margin-top: 12px;
  margin-bottom: 13px !important;
}

.card-data-column-left > p.card-column-header {
  font-size: 20px;
  font-weight: 400;
  line-height: 20px;
}

.card-order-container > p.card-column-header {
  font-size: 20px;
  font-weight: 400;
  line-height: 20px;
}

.payment-processing > div.container > div.message-container {
  text-align: center;
  margin: 20px 0;
}

/*********************************************
 * Mobile layout
 ********************************************/
@media only screen and (max-width: 768px)  {
  .grid.row {
    flex-direction: column;
    align-items: center;
  }

  .button {
    margin-bottom: 0.5em;
    width: 100%;
  }

  .button.primary {
    order: 1;
  }

  .button.button.default {
    order: 2;
  }

  .grid.row {
    padding-top: 0.5rem;
  }

  .grid.row.reversable {
    flex-direction: column-reverse;
    justify-content: space-evenly;
  }

  .card-data {
    flex-direction: column;
    width: 100%;
    min-width: 280px;
  }

  .card-data-column-left {
    min-width: 180px !important;
    width: 100%;
    padding: 0px;
  }

  .card-data-column-right {
    width: 100%;
    padding: 0px;
    flex-direction: column-reverse;
    padding-left: 0 !important;
    min-width: 180px !important;
  }

  .card-data-column-left > .card-field-label {
    margin-left: 0;
    margin-right: 0;
  }

  div.card-data-column-left > label,
  div.card-data-column-left > p {
    margin-left: 24px;
    margin-right: 24px;
  }

  .card-data-column-right > .card-order-container{
    margin-left: 24px;
    margin-right: 24px;
  }

  .card-column-header {
    margin-left: 0 !important;
  }

  .card-data-column-right, .card-order-container, .card-data-column-right {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}

/*********************************************
 * Trade widget theme modifications to default coinify theme.
 ********************************************/

input[type=text]:focus{
  outline: none;
  border: solid 1px #8fc065;
}

.ssl-secured { 
  height: 21px;
}

.payment-container {
  min-width: 180px !important;
}
