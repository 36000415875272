
.heartbeat-loader {
}

.heartbeat-circle {
}

.heartbeat-circle.circle-1 {
  position: absolute;
  border-radius: 50%;
  opacity: 0.7;
  background-color: #FFFFFF;
  animation: iQfAUB 2s infinite ease-in-out;
  animation-direction: alternate;
  animation-duration: 1.0s;
}

.heartbeat-circle.circle-2 {
  position: absolute;
  border-radius: 50%;
  opacity: 0.7;
  background-color: #FFFFFF;
  animation: iQfAUB 2s infinite cubic-bezier(0.42, 0, 0.49, 0.95);
  animation-direction: alternate;
  animation-delay: -1s;
  transform: translate(0%, -100%);
  animation-duration: 1.0s;
}

@-webkit-keyframes iQfAUB {
  0%   { transform: scale(0.0); }
  100% { transform: scale(1.0); }
}




